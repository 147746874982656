<template>
  <div>
    <v-dialog v-model="formOpened" content-class="j_modal type02" persistent width="700">
      <v-card>
        <v-card-title class="modal__header">
          <span>Color</span>
          <j-button class="modal_close" @click="onAction('cancel')">
            <v-icon>mdi-close</v-icon>
          </j-button>
        </v-card-title>

        <v-card-text class="modal__schemeControl">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container class="modal__schemeControl_container">
              <v-flex xs12>
                <v-flex xs12>
                  <v-flex sm2>
                    <v-text-field
                      v-model="editableItem.colorNo"
                      dense
                      disabled
                      label="No. of Colors"
                      class="wrap__text"
                    ></v-text-field>
                  </v-flex>
                  <v-flex sm6>
                    <v-text-field
                      v-model="editableItem.colorName"
                      dense
                      required
                      label="Name"
                      placeholder="Color-Set Name"
                      class="wrap__text"
                    ></v-text-field>
                  </v-flex>
                </v-flex>

                <div class="colorset_container">
                  <div class="colorset">
                    <div
                      v-for="(color, i) in editableItem.colors"
                      class="color"
                      :class="{ selected: isselected(i) }"
                      :key="i"
                      :style="`background-color: ${color}`"
                      @click="onSelect(i)"
                    />
                    <div
                      v-ripple
                      class="color new"
                      :class="{ selected: isselected(-1) }"
                      @click="onSelect(-1)"
                    />
                  </div>
                  <div class="color_action">
                    <div>
                      <div class="color_selected" :style="selectedColorStyle"></div>
                      <button v-ripple class="btn_color_remove" @click="onRemove()">x</button>
                    </div>
                  </div>
                </div>

                <v-card-actions>
                  <j-button
                    v-if="modeNew"
                    :disabled="!resetable"
                    @click="onCreate"
                    class="type01 sky"
                    :class="{ disabled: !resetable }"
                  >Create</j-button>
                  <j-button
                    v-if="modeMod"
                    :disabled="!resetable"
                    @click="onEdit"
                    class="type01 sky"
                    :class="{ disabled: !resetable }"
                  >Edit</j-button>
                  <j-button v-if="modeMod" @click="onDelete" class="type01 delete">Delete</j-button>
                  <j-button @click="onAction('cancel')" class="type01">cancel</j-button>
                </v-card-actions>
              </v-flex>

              <j-color-palette v-model="paletteColor" @input="onPaletteColorSelected" />
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import '../../../assets/stylus/ui/_colorScheme.styl'
import __C from "@/primitives/_constant_"
import { ChartLibraryService } from '@/services'

export default {
  name: 'sysenv-chartlib-bar-modal',
  props: {
    item: { type: Object, default: () => ({}) },
    itemKey: String,
    value: { type: Boolean, default: false }
  },
  data: () => ({
    chartLibraryService: null,
    editableItem: {},
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    msgOpen: false,
    paletteColor: '',
    selected: -1,
    valid: false,
  }),
  computed: {
    modeNew() { return this.formMode == __C.FORM.EDIT_MODE_NEW },
    modeMod() { return this.formMode == __C.FORM.EDIT_MODE_MOD },
    formMode() {
      if (this.itemKey && this.item[this.itemKey]) return __C.FORM.EDIT_MODE_MOD
      else return __C.FORM.EDIT_MODE_NEW
    },
    formOpened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },
    selectedColorStyle() {
      if (
        !this.editableItem ||
        Object.keys(this.editableItem).length === 0 ||
        this.editableItem.colors.length === 0 ||
        this.selected < 0
      ) return ''
      return {
        'background-color': this.editableItem.colors[this.selected],
        'border': 'none'
      }
    }
  },
  watch: {
    formOpened(val) {
      if (!val) return

      this.editableItem = { colors: [] }
      this.selected = -1
      this.paletteColor = ''

      if (this.modeNew) {

        // TO DO

      } else {
        this.editableItem = JSON.parse(JSON.stringify(this.item))
      }
    }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  methods: {
    close() {
      this.formOpened = false
    },
    isselected(i) {
      return this.selected == i
    },
    onAction(action) {
      this.formOpened = false
    },
    onCreate() {
      this.yes = () => {
        this.msgOpen = false
        this.editableItem.colorNo = this.editableItem.colors.length
        this.chartLibraryService.putColorScheme(
          this.editableItem,
          res => {
            this.$emit("update", true)
            this.close()
          }
        )
        this.yes = () => { }
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Save Changes."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to store new Color-Set?"
      this.msgInfo.buttonText[0] = "Save"
      this.msgOpen = true
    },
    onDelete() {
      this.yes = () => {
        this.msgOpen = false
        this.chartLibraryService.delColorScheme(
          this.editableItem.idx,
          res => {
            this.$emit("update", true)
            this.close()
          }
        )
        this.yes = () => { }
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = "Important Notification"
      this.msgInfo.message = "Do you want to delete selected Color-Set?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    onEdit() {
      this.yes = () => {
        this.msgOpen = false
        this.editableItem.colorNo = this.editableItem.colors.length
        this.chartLibraryService.updColorScheme(
          this.editableItem,
          res => {
            this.$emit("update", true)
            this.close()
          }
        )
        this.yes = () => { }
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Save Changes."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to save this changes?"
      this.msgInfo.buttonText[0] = "Save"
      this.msgOpen = true
    },
    onPaletteColorSelected(color) {
      if (this.selected < 0) {
        this.editableItem.colors.push(color)
      } else {
        let colors = JSON.parse(JSON.stringify(this.editableItem.colors))
        colors[this.selected] = color

        this.editableItem.colors = JSON.parse(JSON.stringify(colors))
      }
    },
    onRemove() {
      if (this.selected < 0) return

      this.editableItem.colors.splice(this.selected, 1)
      this.selected = -1
    },
    onSelect(index) {
      this.selected = index
      this.paletteColor = index < 0 ? '' : this.editableItem.colors[index]
    },
    resetable() {
      if (!this.chartMaster) return false

      if (this.modeNew) {

        return false

      } else {

        return false
      }
    },
  }
}
</script>
