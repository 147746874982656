<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                Color
                <small>Scheme</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
              </v-flex>
            </div>
          </div>

          <j-data-grid-tool-bar :headers="headers" :items="items">
            <template v-slot:tool-bar>
              <button
                v-ripple
                class="btn_color_range"
                :class="{ selected: colorRangeSelected(-1) }"
                @click="onColorRangeClicked(-1)"
              >A</button>
              <button
                v-ripple
                v-for="i in numColorsGroup"
                class="btn_color_range"
                :class="{ selected: colorRangeSelected(i) }"
                :key="i"
                @click="onColorRangeClicked(i)"
              >{{ i }}</button>
            </template>
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus(props.item)">
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td>{{ props.item.colorName }}</td>
                <td style="text-align: center;">{{ props.item.colorNo }}</td>
                <td>
                  <div class="palette">
                    <div
                      v-for="color in props.item.colors"
                      class="color_rect"
                      :style="`background-color: ${color};`"
                      :key="color.index"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </j-data-grid-tool-bar>
        </div>
      </div>

      <form-modal v-model="formOpened" item-key="idx" :item="selectedItem" @update="onUpdated" />
    </div>
  </div>
</template>

<script>
import "../../assets/stylus/ui/_colorScheme.styl"
import { ChartLibraryService } from '@/services'
import FormModal from "./modal/SysenvColorSchemes.modal"

export default {
  name: "sysenv-color-schemes",
  components: {
    FormModal
  },
  data: () => ({
    chartLibraryService: null,
    formOpened: false,
    headers: [
      { type: "conf", value: "no", width: 40 },
      { type: "text", value: "COLOR_NAME", width: 120 },
      { type: "text", value: "COLOR_NO", width: 40 },
      { type: "text", value: "" },
    ],
    items: [],
    numColors: -1,
    numColorsGroup: [],
    selectedItem: {},
  }),
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  mounted() {
    this.getColorSchemes()
  },
  methods: {
    colorRangeSelected(num) {
      return this.numColors == num
    },
    getColorSchemes() {
      this.chartLibraryService.getColorSchemes(this.numColors, res => {
        this.items = res.schemes
        this.numColorsGroup = res.nums

      })
    },
    onColorRangeClicked(num) {
      this.numColors = num
      this.getColorSchemes()
    },
    onUpdated() {
      this.getColorSchemes()
    },
    parseColors(item) {
      if (!item) return []

      let colors = []
      Object.keys(item).forEach(k => {
        if (!['no', 'IDX', 'COLOR_NAME', 'COLOR_NO'].includes(k)) colors.push(item[k])
      })
      return colors
    },
    propStatus(item) {
      this.selectedItem = item
      this.formOpened = true
    },
    setAdd() {
      this.selectedItem = {}
      this.formOpened = true
    },
  }
}
</script>
